<template>
  <v-dialog
    v-model="open"
    max-width="500"
  >
    <template #activator="{ on }">
      <v-btn
        v-if="button"
        v-bind="$attrs"
        :color="color"
        v-on="on"
      >
        <v-icon
          small
          class="mr-2"
        >
          {{ icon }} fa-fw
        </v-icon>
        {{ title }}
      </v-btn>

      <template v-else>
        <v-list-item-action
          class="mr-4"
          v-bind="$attrs"
          v-on="on"
        >
          <v-icon small>
            {{ icon }} fa-fw
          </v-icon>
        </v-list-item-action>
        <v-list-item-content
          v-bind="$attrs"
          v-on="on"
        >
          <v-list-item-title>{{ title }}</v-list-item-title>
        </v-list-item-content>
      </template>
    </template>
    <v-card>
      <v-card-title class="mb-1 pb-1">
        <v-icon>
          {{ icon }} mr-3
        </v-icon>
        {{ title }}
      </v-card-title>
      <v-card-subtitle
        v-if="subtitle"
        class="mt-1 pb-2"
        v-text="subtitle"
      />
      <v-card-subtitle
        v-if="record.name"
        class="mt-1 pb-2"
        v-text="record.name"
      />
      <v-card-text>
        <v-row>
          <v-col
            v-if="btnDisabled"
            cols="12"
          >
            <v-alert
              border="right"
              colored-border
              type="error"
              elevation="2"
            >
              {{ msgError }}
            </v-alert>
          </v-col>
          <v-row class="mt-2">
            <v-col
              :cols="range ? 8 : ''"
              class="pb-0"
            >
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="date"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template #activator="{ on, attrs }">
                  <v-text-field
                    :value="valueField"
                    :label="dateRange ? 'Select Months': 'Select Month'"
                    :prepend-icon="dateRange ? 'mdi-calendar-blank-multiple' : 'mdi-calendar-blank'"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="date"
                  no-title
                  scrollable
                  type="month"
                  :max="maxDate"
                  :min="minDate"
                  :range="dateRange"
                  @change="$refs.menu.save(date)"
                />
              </v-menu>
            </v-col>

            <v-col
              v-if="range"
              cols="4"
              class="pb-0 pr-0"
            >
              <v-switch
                v-model="dateRange"
                label="Date range"
              />
            </v-col>
            <v-col
              v-if="showRedacted"
              cols="4"
              md="3"
            >
              <v-checkbox
                v-model="redacted"
                :label="$t('Redacted')"
                class="mt-0"
              />
            </v-col>
          <!-- <v-col
            v-if="multi"
            cols="4"
          >
            <v-checkbox
              v-model="consolidated"
              label="Consolidated"
              :disabled="!multi"
              class="mt-0"
            />
          </v-col> -->
          </v-row>
          <template v-if="!dateRange && $moment(date).add(1, 'month').month() >= $moment().month()">
            <v-row>
              <v-divider />
              <v-col
                cols="12"
                class="pb-0"
              >
                <v-switch
                  v-model="subscribe"
                  label="Automatically generate this report each month"
                  class="my-0 py-0"
                  dense
                />
              </v-col>
              <v-col
                v-if="subscribe"
                class="pt-0"
              >
                <v-label>Send the report to the following email addresses:</v-label>
                <v-combobox
                  v-model="recipients"
                  small-chips
                  outlined
                  multiple
                  dense
                  deletable-chips
                  :rules="[ v => /.+@.+/.test(v) || 'Invalid email address' ]"
                  :delimiters="[',', ';']"
                />
              </v-col>
            </v-row>
          </template>

          <slot
            name="parameters"
          />
        </v-row>
      </v-card-text>
      <v-divider />
      <v-card-actions
        class="text-right"
      >
        <v-spacer />
        <v-btn
          text
          @click="open = false"
        >
          {{ $t('Cancel') }}
        </v-btn>
        <v-btn
          color="primary"
          outlined
          :disabled="btnDisabled"
          xclick="multi && record.length > 1 ? $emit('action', null, date, redacted, consolidated) : $emit('action', record, date, redacted); open = false;"
          @click="$emit('action', record, date, redacted, consolidated, subscribe, recipients); open = false;"
        >
          <slot name="action-button">
            <v-icon
              small
              class="mr-2"
            >
              fal fa-file-download
            </v-icon>
            Download
          </slot>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    applyFor: {
      type: Boolean,
      default: false,
    },
    range: {
      type: Boolean,
      default: false,
    },
    record: {
      type: [Object, Array],
      default: () => ({}),
    },
    url: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: 'Monthly Report',
    },
    subtitle: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: 'fal fa-file-chart-line',
    },
    color: {
      type: String,
      default: 'primary',
    },
    showRedacted: Boolean,
    button: Boolean,
    multi: {
      type: Boolean,
      default: false,
    },
    btnDisabled: {
      type: Boolean,
      default: false,
    },
    msgError: {
      type: String,
      default: 'It is not possible to send the form.',
    },
    consolidatedDisabled:{
      type: Boolean,
      default: false,
    },
    allowSubscription: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      open: false,
      menu: null,
      dateRange: false,
      date: this.$moment().subtract(1,'months').format('Y-MM'),
      redacted: false,
      consolidated: false,
      subscribe: false,
      recipients: [],
    }
  },
  computed: {
    valueField () {
      if (this.dateRange) {
         return this.$moment(this.date[0]).format('MMMM Y') + " → " + ((this.date[1]) ? this.$moment(this.date[1]).format('MMMM Y'): "")
      }
      return this.$moment(this.date).format('MMMM Y')
    },
    minDate () {
      return this.dateRange && this.date.length == 1 ? this.date[0] : null
    },
    maxDate () {
      return this.minDate ? this.$moment().max(this.$moment(this.minDate).add(11, 'months'), this.$moment()).format('Y-MM') : this.$moment().format('Y-MM')
    },
  },
  watch: {
    dateRange (dateRange) {
      if (dateRange) {
        this.date = [
          this.$moment().subtract(6,'months').format('Y-MM'),
          this.$moment().subtract(1,'months').format('Y-MM'),
        ]
      } else {
        this.date = this.$moment().subtract(1,'months').format('Y-MM')
      }
    },
  },
}
</script>
